import { Card, Button, Divider, SkeletonBodyText, Text, Tooltip } from '@shopify/polaris';
import { StyledShipmentInfo } from './styled';
import { RefreshMinor } from '@shopify/polaris-icons';
import { addMinuteQuantity, camelToRegularText, currencyFormatter } from '@/helpers';
import ProductCard from '../ProductCard';
import { Fragment, useMemo } from 'react';
import ShipmentStepper from '../ShipmentStepper';
import { useParams } from 'react-router-dom';
import { useGetShipmentDetailQuery, useResyncShipmentDetailMutation } from '@/redux/api/api.caller';
import { ITrackingDetail } from '@/types/shipment';
import { SkeletonText } from '@/components';
import { calcSubtotalPrice } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import commonSlice from '@/redux/features/common.slice';
import shipmentSlice, { disabledBtnSelector } from '@/redux/features/shipment.slice';
import { handleToastMutation } from '@/helpers/toast';

const ShipmentInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const disabledBtn = useSelector(disabledBtnSelector);
  const isExistedId = disabledBtn.detail?.length > 0 && disabledBtn.detail?.some((item) => item.id === id || '');
  const { data, isLoading } = useGetShipmentDetailQuery({ id: Number(id || '0') });
  const [resyncShipment, resyncShipmentData] = useResyncShipmentDetailMutation();
  const shipment = data?.data?.shipment;

  const isDisabledBtn = useMemo(() => {
    if (isExistedId) {
      const detailInfo = disabledBtn.detail.find((item) => item.id === String(id || ''));
      return new Date() < new Date(detailInfo?.time || '') && String(detailInfo?.id || '') === String(id || '');
    }
    return false;
  }, [disabledBtn.detail, id, isExistedId]);

  const renderShipmentStatusIcon = () => {
    const status = shipment?.trackingSummary || '';
    const svgIcon = (data?.data?.statusConfigs || []).find(({ value }) => value === status)?.icon;
    return <div dangerouslySetInnerHTML={{ __html: svgIcon || '' }} />;
  };

  const renderShipmentDescription = (trackingDetail: ITrackingDetail[]) => {
    if (trackingDetail.length > 0) {
      const trackingPageData = trackingDetail.map(({ date_time, message }) => ({
        time: date_time,
        title: message,
      }));
      return <ShipmentStepper data={trackingPageData} />;
    }
    return <p>Tracking details will be available once carrier returns updates. Please check again later.</p>;
  };

  const handleResync = async () => {
    resyncShipment({
      trackingCompany: shipment?.trackingCompany || '',
      trackingNumber: shipment?.trackingNumber || '',
      orderId: shipment?.orderId || '',
    }).then((data: any) => {
      let updatedDetail = disabledBtn.detail;
      if (data?.data?.state) {
        updatedDetail = isExistedId
          ? disabledBtn.detail.map((item) => {
              if (item.id === String(id || '')) {
                return {
                  ...item,
                  time: addMinuteQuantity(5),
                };
              }
              return item;
            })
          : [
              ...disabledBtn.detail,
              {
                id: id || '',
                time: addMinuteQuantity(5),
              },
            ];
      }
      dispatch(
        shipmentSlice.actions.handleSetDisabledBtnTime({
          ...disabledBtn,
          detail: updatedDetail,
          detailTooltip:
            'Shipment details are re-synced successfully! The latest info is now updated on tracking page as well. You can continue re-syncing for the next 5 minute',
        }),
      );
      dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
    });
  };

  return (
    <StyledShipmentInfo>
      <Card>
        <div className="shipment-header">
          {isLoading ? (
            <div className="header-loading">
              <SkeletonBodyText lines={6} />
            </div>
          ) : (
            <>
              <div className="shipment-title">
                <div className="shipment-status">
                  {renderShipmentStatusIcon()}
                  <Text variant="headingSm" as="h6">
                    {camelToRegularText(shipment?.trackingSummary || '')}
                  </Text>
                </div>
                <Tooltip zIndexOverride={isDisabledBtn ? 400 : -1000} content={disabledBtn.detailTooltip}>
                  <Button
                    variant="primary"
                    icon={RefreshMinor}
                    onClick={handleResync}
                    loading={resyncShipmentData.isLoading}
                    disabled={isDisabledBtn || !shipment?.trackingNumber}
                  >
                    Re-sync
                  </Button>
                </Tooltip>
              </div>
              <Text variant="headingSm" as="h6">
                {shipment?.trackingCompany}
              </Text>
              <div className="mt-8" />
              <Text variant="headingMd" as="h6">
                Shipment progress (in courier's local time)
              </Text>
            </>
          )}
        </div>

        <div className="mt-16">
          <Divider />
        </div>

        <div className="shipment-description">
          {isLoading ? <SkeletonBodyText /> : renderShipmentDescription(shipment?.trackingDetail || [])}
        </div>

        <Divider />

        <div className="shipment-items">
          <Text variant="headingMd" as="h6">
            {isLoading ? (
              <SkeletonText height="1.5rem" width="10rem" />
            ) : (
              `Shipment items (${(shipment?.lineItems || []).length})`
            )}
          </Text>
          <div className="product-list">
            {isLoading ? (
              <div className="product-loading">
                <SkeletonBodyText lines={5} />
              </div>
            ) : (
              (shipment?.lineItems || []).map(({ productId, image, title, quantity, price }, index) => (
                <Fragment key={productId}>
                  <ProductCard
                    image={image || ''}
                    title={title}
                    quantity={quantity.toString()}
                    price={currencyFormatter(shipment?.currency || '').format(Number(price))}
                  />
                  {(shipment?.lineItems || []).length - 1 !== index && <Divider />}
                </Fragment>
              ))
            )}
          </div>
        </div>

        <Divider />

        <div className="shipment-subtotal">
          <Text variant="headingMd" as="h6">
            Items subtotal
          </Text>
          <Text variant="headingMd" as="h6">
            {calcSubtotalPrice(shipment?.lineItems || [], shipment?.currency)}
          </Text>
        </div>
        <Divider />
      </Card>
    </StyledShipmentInfo>
  );
};

export default ShipmentInfo;
