import { Card, Badge, Button, Icon, List, Modal } from '@shopify/polaris';
import { PlayMajor, ProductCostMajor, UndoMajor } from '@shopify/polaris-icons';
import { StyledTemplateButtons, StyledVariableList } from './styled';
import { useMemo, useState } from 'react';
import { ButtonType } from '../../config';
import { VariableTags } from '@/constants';
import { useResetToDefaultMutation, useSendTestEmailMutation } from '@/redux/api/api.caller';
import { useDispatch, useSelector } from 'react-redux';
import { emailTemplatesTabSelector, orderStatusSelector } from '@/redux/features/email.slice';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { tokenSelector } from '@/redux/features/auth.slice';
import { CopyIcon } from '@/icons';
import { ErrorBanner } from '@/components';

interface IModal {
  isActive: boolean;
  type: ButtonType;
}

const TemplateButtons = () => {
  const [apiError, setApiError] = useState<string>('');
  const [modal, setModal] = useState<IModal>({ isActive: false, type: ButtonType.InsertTags });
  const dispatch = useDispatch();
  const orderStatusState = useSelector(orderStatusSelector);
  const { token } = useSelector(tokenSelector);
  const [sendTestEmail, sendTestEmailData] = useSendTestEmailMutation();
  const [resetToDefault, resetToDefaultData] = useResetToDefaultMutation();
  const templatesTab = useSelector(emailTemplatesTabSelector);

  const modalInfo = useMemo(() => {
    switch (modal.type) {
      case ButtonType.SendTestEmail:
        return {
          title: 'Send test email',
          content: (
            <>
              A test email has been sent to your email address, <strong>{token?.email || ''}</strong>. Please check your inbox and
              spam folder to ensure that the email was delivered successfully.
            </>
          ),
          okBtn: 'Send test email',
          cancelBtn: 'Cancel',
        };
      case ButtonType.ResetToDefault:
        return {
          title: 'Reset to default',
          content: 'Are you sure you want to reset this email template to its default settings?',
          okBtn: 'Yes',
          cancelBtn: 'No',
        };
      default:
        return {
          title: 'Insert tags',
          content: 'Choose a tag to insert into the email content below:',
          cancelBtn: 'Cancel',
        };
    }
  }, [modal.type, token?.email]);

  const handleCloseModal = () => {
    setApiError('');
    setModal((prev) => ({ ...prev, isActive: false, type: ButtonType.InsertTags }));
  };

  const handleClickBtn = (type: ButtonType) => setModal((prev) => ({ ...prev, isActive: true, type }));

  const handleConfirmAction = (type: ButtonType) => {
    const actionFunction = type === ButtonType.ResetToDefault ? resetToDefault : sendTestEmail;
    actionFunction({ trackingStatus: orderStatusState, receiver: templatesTab }).then((data: any) => {
      if (data.data) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        handleCloseModal();
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  return (
    <StyledTemplateButtons>
      <Card>
        <div className="btn-group">
          <Button icon={<Icon source={ProductCostMajor} tone="base" />} onClick={() => handleClickBtn(ButtonType.InsertTags)}>
            Insert tags
          </Button>
          <Button icon={<Icon source={UndoMajor} tone="base" />} onClick={() => handleClickBtn(ButtonType.ResetToDefault)}>
            Reset to default
          </Button>
          <Button icon={<Icon source={PlayMajor} tone="base" />} onClick={() => handleClickBtn(ButtonType.SendTestEmail)}>
            Send test email
          </Button>
        </div>
      </Card>

      <Modal
        open={modal.isActive}
        onClose={handleCloseModal}
        title={modalInfo.title}
        primaryAction={
          modalInfo.okBtn
            ? {
                content: modalInfo.okBtn,
                onAction: () => handleConfirmAction(modal.type),
                loading: resetToDefaultData.isLoading || sendTestEmailData.isLoading,
              }
            : undefined
        }
        secondaryActions={[
          {
            content: modalInfo.cancelBtn,
            onAction: handleCloseModal,
          },
        ]}
      >
        <Modal.Section>
          <ErrorBanner isVisible={!!apiError}>{apiError}</ErrorBanner>
          <p>{modalInfo.content}</p>
          {modal.type === ButtonType.InsertTags && (
            <StyledVariableList>
              <List>
                {Object.values(VariableTags).map((variable) => (
                  <List.Item key={variable}>
                    <div className="variable-item">
                      <div className="variable-badge">
                        <Badge tone="info">{`{{!! ${variable} !!}}`}</Badge>
                      </div>
                      <CopyIcon copiedText={`{{!! ${variable} !!}}`} />
                    </div>
                  </List.Item>
                ))}
              </List>
            </StyledVariableList>
          )}
        </Modal.Section>
      </Modal>
    </StyledTemplateButtons>
  );
};

export default TemplateButtons;
