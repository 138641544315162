import { CustomCard } from '@/components';
import trackingPageSlice, { trackingPageIsLoadingSelector, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { TextField, Text, SkeletonBodyText } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import Editor from '@monaco-editor/react';
import { checkDirtyField } from '@/helpers';
const AdditionalContent = () => {
  const dispatch = useDispatch();
  const data = useSelector(trackingPageSelector).value;
  const {
    fieldsChange,
    oldValue: {
      configuration: { additionalContent: oldAdditionalContent },
    },
  } = useSelector(trackingPageSelector);
  const isLoading = useSelector(trackingPageIsLoadingSelector);
  const {
    configuration: { additionalContent },
  } = data;

  const handleAdditionalField = (currentValue: {}) => {
    const { dirtyField: additionalContentField } = checkDirtyField(oldAdditionalContent, currentValue);
    return additionalContentField;
  };

  const handleChange = (key: string) => (value?: string) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          additionalContent: {
            ...additionalContent,
            [key]: value,
          },
        },
      }),
    );

    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        additional: handleAdditionalField({ ...additionalContent, [key]: value }),
      }),
    );
  };
  return (
    <CustomCard title="Additional Content">
      {isLoading ? (
        <SkeletonBodyText lines={30} />
      ) : (
        <div>
          <TextField
            label="Text above"
            value={additionalContent.textAbove}
            onChange={handleChange('textAbove')}
            autoComplete="off"
            multiline={3}
            maxLength={255}
            showCharacterCount
          />
          <div className="mt-16">
            <TextField
              label="Text below"
              value={additionalContent.textBelow}
              onChange={handleChange('textBelow')}
              autoComplete="off"
              multiline={3}
              maxLength={255}
              showCharacterCount
            />
          </div>
          <div className="mt-16">
            <Text as="h6" variant="bodySm">
              HTML top of page
            </Text>
            <Editor
              className="mt-4"
              onChange={handleChange('htmlTop')}
              height="12rem"
              theme="vs-dark"
              defaultLanguage="html"
              value={additionalContent.htmlTop}
            />
          </div>
          <div className="mt-16">
            <Text as="h6" variant="bodySm">
              HTML bottom of page
            </Text>
            <Editor
              className="mt-4"
              onChange={handleChange('htmlBottom')}
              height="12rem"
              theme="vs-dark"
              defaultLanguage="html"
              value={additionalContent.htmlBottom}
            />
          </div>
          <div className="mt-16">
            <Text as="h6" variant="bodySm">
              Custom CSS
            </Text>
            <Editor
              className="mt-4"
              onChange={handleChange('customCss')}
              height="12rem"
              theme="vs-dark"
              defaultLanguage="css"
              value={additionalContent.customCss}
            />
          </div>
        </div>
      )}
    </CustomCard>
  );
};

export default AdditionalContent;
