import { CustomCard } from '@/components';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import CustomAutocomplete from '@/pages/Settings/components/CourierMapping/components/AutoComplete';
import { useGetCourierMappingQuery, useGetListCourierQuery, useUpdateCourierSettingMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import settingsSlice, { courierMappingSelector } from '@/redux/features/settings.slice';
import { Button, Icon, SkeletonBodyText, Text } from '@shopify/polaris';
import { DeleteMinor, SearchMinor } from '@shopify/polaris-icons';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';

const CourierSettings = () => {
  const fetchCourierMapping = useGetCourierMappingQuery().data;
  const { data, isLoading } = useGetListCourierQuery({ page: 1, perPage: 500 });
  const dispatch = useDispatch();
  const courierMapping = useSelector(courierMappingSelector);
  const [listCourier, setListCourier] = useState<Array<{ label: string; value: string }>>([]);
  const [courierSetting, courierSettingStatus] = useUpdateCourierSettingMutation();
  const [oldValue, setOldValue] = useState<Array<string>>([]);
  const [apiError, setApiError] = useState<string>('');

  useEffect(() => {
    if (fetchCourierMapping) {
      setOldValue(fetchCourierMapping.data.courierSettings);
      dispatch(settingsSlice.actions.handleCourierMapping({ courierList: fetchCourierMapping.data.courierSettings }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCourierMapping]);

  useEffect(() => {
    if (data) {
      const baseOptions = data.data.couriers.map((item) => {
        return {
          label: item.display_name,
          value: item.name,
        };
      });
      setListCourier(baseOptions);
    }
  }, [data]);

  const updateCourierSetting = () => {
    courierSetting({ payload: courierMapping.courierList }).then((res: any) => {
      if (res.data) {
        setOldValue(courierMapping.courierList);
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        return;
      }
      setApiError(getErrorFromAPI(res));
    });
  };
  const handleSortList = (
    list: Array<{
      id: number;
      name: string;
    }>,
  ) => {
    const result = list.map((item) => item.name);
    dispatch(
      settingsSlice.actions.handleCourierMapping({
        ...courierMapping,
        courierList: result,
      }),
    );
  };
  const getSortList = useMemo(() => {
    const result = courierMapping.courierList.map((courier, index) => {
      return {
        id: index + 1,
        name: courier,
      };
    });
    return result;
  }, [courierMapping.courierList]);
  const handleUpdateCourierSetting = (value: Array<string>) => {
    setApiError('');
    dispatch(
      settingsSlice.actions.handleCourierMapping({
        ...courierMapping,
        courierList: value,
      }),
    );
  };

  const handleDeleteCourierSettings = (id: number) => () => {
    const listCourierSettings = [...courierMapping.courierList];
    listCourierSettings.splice(id, 1);
    dispatch(
      settingsSlice.actions.handleCourierMapping({
        ...courierMapping,
        courierList: listCourierSettings,
      }),
    );
  };

  return (
    <CustomCard
      title="Frequently used couriers"
      actionGroup={[
        {
          content: 'Save',
          variant: 'primary',
          onAction: updateCourierSetting,
          disabled: oldValue.toString() === courierMapping.courierList.toString(),
          loading: courierSettingStatus.isLoading,
        },
      ]}
    >
      <Text as="p" variant="bodySm">
        When you have a new tracking number, the system will priortize detect couriers based on this list
      </Text>

      <div className="mt-16">
        <CustomAutocomplete
          placeholder="USPS, UPS,..."
          handleSelected={handleUpdateCourierSetting}
          label="Couriers list"
          baseOptions={listCourier}
          prefix={<Icon source={SearchMinor} tone="base" />}
          selected={courierMapping.courierList}
          loading={isLoading}
          allowMultiple
          error={apiError}
        />
      </div>
      <div className="mt-8">
        {isLoading ? (
          <SkeletonBodyText lines={5} />
        ) : (
          <ReactSortable list={getSortList} setList={handleSortList}>
            {getSortList.map((item, index) => {
              const courier = data?.data.couriers.find((courier) => courier.name === item.name);
              return (
                <div key={item.id} className="list-sortable">
                  <div className="left-item">
                    <Text as="h6" variant="headingSm">
                      {item.id}
                    </Text>
                    <img className="ml-16" src={courier?.logo || ''} alt={item.name} />
                    <div className="ml-16">{courier?.display_name || ''}</div>
                  </div>
                  <div className="delete-icon">
                    <Button icon={DeleteMinor} onClick={handleDeleteCourierSettings(index)} />
                  </div>
                </div>
              );
            })}
          </ReactSortable>
        )}
      </div>
    </CustomCard>
  );
};

export default CourierSettings;
