import { Banner, BannerProps, Collapsible } from '@shopify/polaris';
import { memo } from 'react';
import { handleOpenChat } from '@/helpers';

interface ErrorBannerProps extends BannerProps {
  title?: string;
  isVisible: boolean;
  children: React.ReactNode;
}

const ErrorBanner = ({ title, children, isVisible, ...props }: ErrorBannerProps) => {
  return (
    <Collapsible open={isVisible} id="basic-collapsible" transition={{ duration: '500ms' }} expandOnPrint>
      <Banner title={title} tone="critical" action={{ content: 'Contact support', onAction: handleOpenChat }} {...props}>
        {children}
      </Banner>
    </Collapsible>
  );
};

export default memo(ErrorBanner);
