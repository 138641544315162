import { Card, Icon, InlineGrid } from '@shopify/polaris';
import { CancelMajor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { CustomCard } from '@/components';
import dashboardPageSlice, { haveClosedRecommendedAppsSelector } from '@/redux/features/dashboard.slice';
import { recommendedAppsData } from './config';
import AppInfoCard from './components/AppInfoCard';
import { useSendGa4AdminAnalyticsMutation } from '@/redux/api/api.caller';
import { StyledRecommendedApps } from './styled';
import { useOnScreen } from '@/hooks';
import { useEffect, useRef, useState } from 'react';
import { Environment } from '@/constants';

const RecommendedApps = () => {
  const [sendGa4Analytics] = useSendGa4AdminAnalyticsMutation();
  const [isCalledAPI, setCalledAPI] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref, '-300px');
  const dispatch = useDispatch();
  const haveClosedRecommendedApps = useSelector(haveClosedRecommendedAppsSelector);

  const handleRemoveRecommendedApps = () => {
    dispatch(dashboardPageSlice.actions.handleCloseRecommendedApps(true));
  };

  useEffect(() => {
    if (isVisible && !isCalledAPI && process.env.REACT_APP_ENV === Environment.Production) {
      sendGa4Analytics({ eventName: 'impress_homeroll1' });
      sendGa4Analytics({ eventName: 'impress_homeroll2' });
      sendGa4Analytics({ eventName: 'impress_homeroll3' });
      setCalledAPI(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalledAPI, isVisible]);

  return (
    <StyledRecommendedApps $isClose={haveClosedRecommendedApps} ref={ref}>
      <CustomCard
        title="Other high-quality apps from Synctrack"
        actionGroup={[
          {
            content: (
              <div className="cursor-pointer" onClick={handleRemoveRecommendedApps}>
                <Icon source={CancelMajor} tone="base" />
              </div>
            ),
          },
        ]}
      >
        <InlineGrid columns={{ xs: 1, sm: 1, md: 1, lg: 3, xl: 3 }} gap="400">
          {recommendedAppsData.map(({ logo, name, isBfs, rating, plan, description, link, eventName }, index) => (
            <Card key={index}>
              <AppInfoCard
                logo={logo}
                name={name}
                isBfs={isBfs}
                rating={rating}
                plan={plan}
                description={description}
                link={link}
                eventName={eventName}
              />
            </Card>
          ))}
        </InlineGrid>
      </CustomCard>
    </StyledRecommendedApps>
  );
};

export default RecommendedApps;
