import SenderInfo from './components/SenderInfo';
import { SkeletonBodyText, Tabs } from '@shopify/polaris';
import { EmailProvider, EmailTabs } from '@/constants';
import { useDisconnectEmailMutation, useGetNotificationAccountsQuery } from '@/redux/api/api.caller';
import { CustomBanner, CustomCard } from '@/components';
import EmailConnectProvider from './components/EmailConnectProvider';
import { useMemo, useState } from 'react';
import SwitchToNewVersion from './components/SwitchToNewVersion';
import { useDispatch, useSelector } from 'react-redux';
import emailSlice, { emailTabSelector, haveClosedBannerSelector, showNewVersionSelector } from '@/redux/features/email.slice';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { renderTabsConfig } from './config';

const EmailNotifications = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const isShowNewVersion = useSelector(showNewVersionSelector);
  const haveClosedBanner = useSelector(haveClosedBannerSelector);
  const selectedTab = useSelector(emailTabSelector);
  const tabIndex = Object.values(EmailTabs).findIndex((tab) => tab === selectedTab);
  const [disconnectEmail, disconnectEmailData] = useDisconnectEmailMutation();
  const { data, isLoading } = useGetNotificationAccountsQuery();
  const emailAccount = data?.data?.email?.account;

  const connectBusinessEmail = useMemo(() => {
    if (isLoading) {
      return (
        <CustomCard title="Sender info">
          <div style={{ height: 300 }}>
            <SkeletonBodyText lines={15} />
          </div>
        </CustomCard>
      );
    }
    if (!emailAccount || emailAccount.gateway === EmailProvider['Amazon SES']) {
      return <SenderInfo />;
    }
    return <EmailConnectProvider />;
  }, [emailAccount, isLoading]);

  const handleKeepCurrentVersion = () => {
    dispatch(emailSlice.actions.handleShowNewVersion(false));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handlePreviewNewVersion = () => dispatch(emailSlice.actions.handleShowNewVersion(true));

  const handleSwitchNewVersion = () => {
    disconnectEmail({}).then((res: any) => {
      if (res.data) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        dispatch(emailSlice.actions.handleShowNewVersion(false));
        dispatch(emailSlice.actions.handleOpenNewVersionModal(false));
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setApiError('');
        return;
      }
      setApiError(getErrorFromAPI(res));
    });
  };

  const handleCloseBanner = () => {
    dispatch(emailSlice.actions.handleCloseBanner(true));
  };

  const handleChangeTab = (tab: number) => {
    dispatch(emailSlice.actions.handleChangeEmailTab(Object.values(EmailTabs)[tab]));
  };

  const tabsConfig = renderTabsConfig(
    <>
      {emailAccount && emailAccount.gateway !== EmailProvider['Amazon SES'] && (
        <CustomBanner
          isClose={haveClosedBanner}
          maxHeight="13.5rem"
          title="New email version available"
          secondaryAction={{ content: 'Preview new version', onAction: handlePreviewNewVersion }}
          tone="info"
          onDismiss={handleCloseBanner}
        >
          We have released the new version of the email notification feature. You can decide whether to keep the current version
          or switch to the new one by previewing with the button below
        </CustomBanner>
      )}
      <SwitchToNewVersion
        isOpen={isShowNewVersion}
        onKeepCurrentVersion={handleKeepCurrentVersion}
        onSwitchNewVersion={handleSwitchNewVersion}
        isLoading={disconnectEmailData.isLoading}
        error={apiError}
      />
      {connectBusinessEmail}
    </>,
  );

  return (
    <Tabs tabs={tabsConfig} selected={tabIndex} onSelect={handleChangeTab}>
      <div style={{ paddingTop: 16 }}>{tabsConfig[tabIndex].component}</div>
    </Tabs>
  );
};

export default EmailNotifications;
