import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import store from '../store';
import authSlice from '../features/auth.slice';
import { convertQueryStringToObject } from '@/helpers';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/s/api/`;

const customBaseQuery = () => {
  const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL });
  return async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    const response = await baseQuery(args, api, extraOptions);
    if (response.error?.status === 401) {
      const search = convertQueryStringToObject(window.location.search);
      const dispatch = store.dispatch;
      if (
        !search?.shop ||
        (search?.shop && store.getState().auth.data.token.shop && search?.shop === store.getState().auth.data.token.shop)
      ) {
        dispatch(authSlice.actions.handleAuth({ ...store.getState().auth.data, token: null, isAuth: false }));
      }
    }
    return response;
  };
};

export default customBaseQuery;
