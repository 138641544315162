import { CustomCard, ImagePreview } from '@/components';
import trackingPageSlice, { trackingPageIsLoadingSelector, trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { Checkbox, SkeletonBodyText, TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { checkDirtyField, splitObjects } from '@/helpers';

const NoInfoOrder = () => {
  const isLoading = useSelector(trackingPageIsLoadingSelector);

  const dispatch = useDispatch();
  const data = useSelector(trackingPageSelector).value;
  const {
    fieldsChange,
    oldValue: {
      configuration: { notInfoOrder: oldNotInfoOrder },
    },
  } = useSelector(trackingPageSelector);
  const { notInfoOrder } = data.configuration;

  const handleNoInfoField = (currentValue: {}) => {
    const { dirtyField: noInfoField } = checkDirtyField(splitObjects(oldNotInfoOrder, 0, 1), {
      ...currentValue,
    });

    const { dirtyField: notFoundField } = checkDirtyField(splitObjects(oldNotInfoOrder, 1, 2), {
      ...currentValue,
    });

    const { dirtyField: messageField } = checkDirtyField(splitObjects(oldNotInfoOrder, 2, 3), {
      ...currentValue,
    });

    if (!noInfoField && !notFoundField && !messageField) {
      return undefined;
    }
    return {
      trackUrl: !noInfoField ? undefined : { isActive: noInfoField?.isNoInfo },
      isReplaceMessage: notFoundField?.isNotFound,
      replaceMessage: messageField?.message,
    };
  };

  const handleChange = (key: string) => (value: string | boolean) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        configuration: {
          ...data.configuration,
          notInfoOrder: {
            ...notInfoOrder,
            [key]: value,
          },
        },
      }),
    );
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        customMessage: handleNoInfoField({ ...notInfoOrder, [key]: value }),
      }),
    );
  };

  return (
    <>
      <CustomCard
        title="“No info” orders solved by adding other tracking link"
        actionGroup={[
          {
            content: (
              <ImagePreview
                imageSrc={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/images/no_info.webp`}
                imageAlt="No info"
                type="text"
                text="View example"
              />
            ),
          },
        ]}
      >
        {isLoading ? (
          <SkeletonBodyText />
        ) : (
          <Checkbox
            label="When the status is “No info”, buyer is able to view tracking info on direct courier’s tracking page by clicking the link provided"
            checked={notInfoOrder.isNoInfo}
            onChange={handleChange('isNoInfo')}
          />
        )}
      </CustomCard>
      <CustomCard
        className="mt-16"
        title="“Not found” orders solved by customizing message"
        actionGroup={[
          {
            content: (
              <ImagePreview
                imageSrc={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/images/not_found.webp`}
                imageAlt="Not found"
                type="text"
                text="View example"
              />
            ),
          },
        ]}
      >
        {isLoading ? (
          <SkeletonBodyText lines={8} />
        ) : (
          <div>
            <Checkbox
              label="When the status is Not found on the tracking page, comfort your customers with a custom message."
              checked={notInfoOrder.isNotFound}
              onChange={handleChange('isNotFound')}
            />
            <div className="mt-8">
              <TextField
                label=""
                labelHidden
                value={notInfoOrder.message}
                onChange={handleChange('message')}
                autoComplete="off"
                multiline={3}
                showCharacterCount
                maxLength={200}
              />
            </div>
          </div>
        )}
      </CustomCard>
    </>
  );
};

export default NoInfoOrder;
