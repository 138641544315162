import { CustomCard, ErrorBanner } from '@/components';
import { StyledIntegration } from './styled';
import { Badge, Button, Collapsible, Divider, Form, FormLayout, Icon, InlineError, TextField } from '@shopify/polaris';
import { HideMinor, MobilePlusMajor, ViewMinor } from '@shopify/polaris-icons';
import { LINK } from '@/constants';
import { useDispatch, useSelector } from 'react-redux';
import settingsSlice, { integrationSelector } from '@/redux/features/settings.slice';
import {
  useCreateIntegrationAvadaMutation,
  useDeleteIntegrationAvadaMutation,
  useGetIntegrationAvadaQuery,
} from '@/redux/api/api.caller';
import { useEffect, useState } from 'react';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import IntegrationCard from './components/IntegrationCard';

const Integration = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<{ create: string; delete: string }>({ create: '', delete: '' });
  const integrationState = useSelector(integrationSelector);
  const { data, isFetching } = useGetIntegrationAvadaQuery();
  const [createAvada, createdAvadaData] = useCreateIntegrationAvadaMutation();
  const [deleteAvada, deletedAvadaData] = useDeleteIntegrationAvadaMutation();

  const handleToggleConnectForm = () =>
    dispatch(settingsSlice.actions.handleChangeIntegration({ ...integrationState, isCollapse: !integrationState.isCollapse }));

  const handleToggleShowPassword = () =>
    dispatch(
      settingsSlice.actions.handleChangeIntegration({
        ...integrationState,
        secretKey: { ...integrationState.secretKey, isShow: !integrationState.secretKey.isShow },
      }),
    );

  const handleChangeTextfield = (value: string, key: string) => {
    const validateTextfield = () => {
      const regEx = new RegExp('^[a-zA-Z0-9]*$');
      if (!regEx.test(value)) {
        return 'Field is invalid';
      }
      if (!value) {
        return 'Field is required';
      }
      return '';
    };
    dispatch(
      settingsSlice.actions.handleChangeIntegration({
        ...integrationState,
        [key]: { ...{ integrationState }[key], value, error: validateTextfield() },
      }),
    );
    setApiError({
      ...apiError,
      create: '',
    });
  };

  const handleCreateNewAvada = () => {
    createAvada({ avadaAppId: integrationState.appID.value, avadaSecretKey: integrationState.secretKey.value }).then(
      (data: any) => {
        if (data.data) {
          setApiError({
            ...apiError,
            create: '',
          });
          dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
          return;
        }
        setApiError({
          ...apiError,
          create: getErrorFromAPI(data),
        });
      },
    );
  };

  const handleDisconnectAvada = () => {
    deleteAvada().then((data: any) => {
      if (data.data) {
        setApiError({
          ...apiError,
          delete: '',
        });
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        dispatch(
          settingsSlice.actions.handleChangeIntegration({
            ...integrationState,
            isConnected: false,
            isCollapse: false,
            appID: { ...integrationState.appID, value: '' },
            secretKey: { ...integrationState.secretKey, value: '' },
          }),
        );
        return;
      }
      setApiError({
        ...apiError,
        delete: getErrorFromAPI(data),
      });
    });
  };

  useEffect(() => {
    if (data && data.data) {
      dispatch(
        settingsSlice.actions.handleChangeIntegration({
          ...integrationState,
          isConnected: !!data.data.shop,
          appID: { ...integrationState.appID, value: data.data.avadaAppId },
          secretKey: { ...integrationState.secretKey, value: data.data.avadaSecretKey },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <StyledIntegration>
      <CustomCard title="Integration">
        <IntegrationCard
          avatar={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/images/avadaLogo.svg`}
          avatarAlt="Avada Logo"
          title="AVA SMS,Email Marketing,Pop Up"
          description="Send out personalized notifications by SMS, Email, Whatsapp with shipping updates from Omega - Order tracking."
          platform="Avada"
          platformLink={LINK.INTEGRATE_AVADA}
          buttonGroup={
            !integrationState.isConnected && (
              <Button variant="primary" icon={MobilePlusMajor} onClick={handleToggleConnectForm} disabled={isFetching}>
                Connect
              </Button>
            )
          }
          getAppLink={LINK.GET_AVADA_APP}
        />

        {integrationState.isConnected ? (
          <>
            <div className="connected-result">
              <div className="left-content">
                <span className="app-id">App ID:</span>
                <Badge progress="complete" tone={'success'}>
                  {integrationState.appID.value}
                </Badge>
              </div>
              <Button disabled={deletedAvadaData.isLoading} loading={deletedAvadaData.isLoading} onClick={handleDisconnectAvada}>
                Disconnect
              </Button>
            </div>
            {apiError.delete && <InlineError message={apiError.delete} fieldID="disconnect-app" />}
          </>
        ) : (
          <Collapsible
            open={integrationState.isCollapse}
            id="basic-collapsible"
            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
            expandOnPrint
          >
            <Form onSubmit={handleCreateNewAvada}>
              <FormLayout>
                <ErrorBanner isVisible={!!apiError.create}>{apiError.create}</ErrorBanner>
                <TextField
                  label="App ID"
                  id="appID"
                  autoComplete="off"
                  value={integrationState.appID.value}
                  onChange={handleChangeTextfield}
                  error={integrationState.appID.error}
                />
                <TextField
                  label="Secret Key"
                  id="secretKey"
                  type={integrationState.secretKey.isShow ? 'text' : 'password'}
                  suffix={
                    <div onClick={handleToggleShowPassword} className="eye-icon">
                      <Icon source={integrationState.secretKey.isShow ? HideMinor : ViewMinor} tone="base" />
                    </div>
                  }
                  autoComplete="off"
                  value={integrationState.secretKey.value}
                  onChange={handleChangeTextfield}
                  error={integrationState.secretKey.error}
                />
                <Button
                  variant="primary"
                  submit
                  disabled={
                    !!integrationState.appID.error ||
                    !!integrationState.secretKey.error ||
                    !integrationState.appID.value ||
                    !integrationState.secretKey.value
                  }
                  loading={createdAvadaData.isLoading}
                >
                  Save
                </Button>
              </FormLayout>
            </Form>
          </Collapsible>
        )}

        <div style={{ marginBlock: 16 }}>
          <Divider />
        </div>
        <IntegrationCard
          avatar={`${process.env.REACT_APP_S3_ASSETS_BASE_URL}/images/pagefly.png`}
          avatarAlt="PageFly Logo"
          title="PageFly Landing Page Builder"
          description="Build Landing, Product Page. Compatible with Online Store 2.0"
          // platform="PageFly"
          // platformLink={LINK.INTEGRATE_PAGE_FLY}
          getAppLink={LINK.GET_PAGE_FLY_APP}
        />
      </CustomCard>
    </StyledIntegration>
  );
};

export default Integration;
