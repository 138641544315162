import { AppInfoCardProps } from './components/AppInfoCard';
import { LINK } from '@/constants';

export const recommendedAppsData: AppInfoCardProps[] = [
  {
    logo: `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/logo/returns_drive_logo.png`,
    name: 'Synctrack Returns & Exchanges',
    isBfs: true,
    rating: '5.0',
    plan: 'Free Plan Available',
    description: 'Make returns and exchanges to reduce costs. Faster returns, retain customers and recapture revenue.',
    link: LINK.RETURNS_DRIVE_APP,
    eventName: 'click_homeroll3',
  },
  {
    logo: `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/logo/synctrack_logo.png`,
    name: 'Synctrack PayPal Tracking Sync',
    isBfs: true,
    rating: '5.0',

    plan: 'Free Plan Available',
    description: 'Real-time sync Paypal & Stripe tracking info for faster funds release. Avoid disputes & chargebacks.',
    link: LINK.SYNC_TRACK_APP,
    eventName: 'click_homeroll1',
  },

  {
    logo: `${process.env.REACT_APP_S3_ASSETS_BASE_URL}/logo/blockify.webp`,
    name: 'Blockify: Fraud IP Blocker',
    isBfs: true,
    rating: '4.9',
    plan: '7-day free trial',
    description: 'Fraud filter, blocking IP addresses, states, countries, bots and scammers using Proxy, VPN connections.',
    link: LINK.BLOCKIFY_APP,
    eventName: 'click_homeroll2',
  },
];
