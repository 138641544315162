import { CustomCard, CustomCollapsible } from '@/components';
import { InlineGrid, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';
import LookupOption from './components/LookupOption';
import Content from './components/Content';
import { useSelector } from 'react-redux';
import { trackingPageIsLoadingSelector } from '@/redux/features/trackingPage.slice';

const configLeftContent = [
  {
    title: 'Lookup options',
    content: <LookupOption />,
  },
  {
    title: 'Content',
    content: <Content />,
  },
];

const TrackingOptions = () => {
  const isLoading = useSelector(trackingPageIsLoadingSelector);

  return (
    <CustomCard title="Tracking options">
      {isLoading ? (
        <InlineGrid columns={{ xl: 2, lg: 2, md: 2, xs: 1, sm: 1 }} gap="800">
          <div>
            <div>
              <div className="mt-16">
                <SkeletonDisplayText size="small" />
              </div>
              <div className="mt-16">
                <SkeletonBodyText lines={8} />
              </div>
            </div>
            <div className="mt-16">
              <div className="mt-16">
                <SkeletonDisplayText size="small" />
              </div>
              <div className="mt-16">
                <SkeletonBodyText lines={8} />
              </div>
            </div>
            <div className="mt-16">
              <div className="mt-16">
                <SkeletonDisplayText size="small" />
              </div>
              <div className="mt-16">
                <SkeletonBodyText lines={8} />
              </div>
            </div>
            <div className="mt-16">
              <div className="mt-16">
                <SkeletonDisplayText size="small" />
              </div>
              <div className="mt-16">
                <SkeletonBodyText lines={8} />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <SkeletonDisplayText size="small" />
              </div>
              <div className="mt-16">
                <SkeletonBodyText lines={20} />
              </div>
            </div>
          </div>
        </InlineGrid>
      ) : (
        <>
          <InlineGrid columns={{ xl: 1, lg: 1, md: 1, xs: 1, sm: 1 }} gap="800">
            <div>
              {configLeftContent.map((item, index) => {
                return (
                  <CustomCollapsible open key={index} title={item.title}>
                    {item.content}
                  </CustomCollapsible>
                );
              })}
            </div>
          </InlineGrid>
        </>
      )}
    </CustomCard>
  );
};
export default TrackingOptions;
