import { IShipmentExportParams } from '@/types/shipment';
import axios, { AxiosRequestConfig } from 'axios';
import { getAppParams } from './param';
import { IRequestParams } from '@/types';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}/s/api/`;

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const convertParamsToQueryString = <T extends object>(params: T) =>
  Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

export const convertQueryStringToObject = (queryString: string) => {
  if (!queryString) {
    return null;
  }
  return JSON.parse(
    '{"' + decodeURI(queryString.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
  );
};

export const customApi = {
  get: function <T, P extends object>(url: string, params: P, config?: AxiosRequestConfig) {
    return axiosClient.get<T>(`${url}?${convertParamsToQueryString(params)}`, config);
  },

  post: function <T, D>(url: string, data: D, config?: AxiosRequestConfig) {
    return axiosClient.post<T>(url, data, config);
  },

  patch: function <T, D>(url: string, data: D, config?: AxiosRequestConfig) {
    return axiosClient.patch<T>(url, data, config);
  },

  put: function <T, D>(url: string, data: D, config?: AxiosRequestConfig) {
    return axiosClient.put<T>(url, data, config);
  },

  delete: function <T>(url: string, config?: AxiosRequestConfig) {
    return axiosClient.delete<T>(url, config);
  },
};

export const exportListShipmentsFile = async (params: IShipmentExportParams) =>
  customApi.get<Blob, any>('/shipment/export', getAppParams(params), { responseType: 'blob' });

export const exportSMSPriceFile = async () =>
  customApi.get<Blob, IRequestParams>('/notification/sms/pricing/export', getAppParams(), { responseType: 'blob' });
