import { IConfigTab } from '@/types/tab';
import LookAndFeel from './components/LookAndFeel';
import {
  CalendarTimeMinor,
  CartDownMajor,
  CodeMajor,
  PagePlusMajor,
  QuestionMarkMajor,
  SmileyJoyMajor,
  StoreStatusMajor,
  TeamMajor,
  TextBlockMajor,
  ThemeEditMajor,
  TranslateMajor,
  ShipmentMajor,
} from '@shopify/polaris-icons';
import LanguageTrackingPage from './components/Language';
import EstimatedDeliveryDate from './components/EDD';
import CustomStatus from './components/CustomStatus';
import ProductRecommendation from './components/ProductRecommendation';
import Review from './components/Review';
import FooterLink from './components/FooterLink';
import NoInfoOrder from './components/NoInfoOrder';
import AdditionalContent from './components/AdditionalContent';
import Blacklisting from './components/Blacklisting';
import { TrackingPageTabs } from '@/constants';
import EmbedTrackingPage from './components/EmbedTrackingPage';
import TrackingOptions from './components/TrackingOptions';
export const tabsConfigPage: Array<IConfigTab> = [
  {
    value: TrackingPageTabs.SetUp,
    label: 'Tracking page set up',
    component: <EmbedTrackingPage />,
    icon: PagePlusMajor,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.LookAndFeel,
    label: 'Look & Feel',
    component: <LookAndFeel />,
    icon: ThemeEditMajor,
  },
  {
    value: TrackingPageTabs.TrackingOptions,
    label: 'Tracking options',
    component: <TrackingOptions />,
    icon: ShipmentMajor,
  },
  {
    value: TrackingPageTabs.Language,
    label: 'Languages',
    component: <LanguageTrackingPage />,
    icon: TranslateMajor,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.Edd,
    label: 'Estimated Delivery Date',
    component: <EstimatedDeliveryDate />,
    icon: CalendarTimeMinor,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.Blacklist,
    label: 'Blacklisting',
    component: <Blacklisting />,
    icon: TextBlockMajor,
    hideControlBar: true,
  },
  {
    value: TrackingPageTabs.CustomStatus,
    label: 'Custom status',
    component: <CustomStatus />,
    icon: StoreStatusMajor,
  },
  {
    value: TrackingPageTabs.ProductRecommendation,
    label: 'Product Recommendation',
    component: <ProductRecommendation />,
    icon: CartDownMajor,
  },
  // {
  //   value: TrackingPageTabs.Review,
  //   label: 'Review',
  //   component: <Review />,
  //   icon: SmileyJoyMajor,
  // },
  {
    value: TrackingPageTabs.Social,
    label: 'Social & Policy',
    component: <FooterLink />,
    icon: TeamMajor,
  },
  {
    value: TrackingPageTabs.NoInfo,
    label: 'No info/Not found order',
    component: <NoInfoOrder />,
    icon: QuestionMarkMajor,
  },
  {
    value: TrackingPageTabs.HtmlCss,
    label: 'HTML & CSS',
    component: <AdditionalContent />,
    icon: CodeMajor,
  },
];

export const isNotChangeData = (oldData: string[], currentData: string[]) => {
  return (
    [...oldData].sort((n1: string, n2: string) => n1.localeCompare(n2)).join('') ===
    [...currentData].sort((n1: string, n2: string) => n1.localeCompare(n2)).join('')
  );
};
