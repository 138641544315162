import { checkDirtyField, isHexColor, splitObjects } from '@/helpers';
import { hexToHSB } from '@/helpers/color';
import trackingPageSlice, { trackingPageSelector } from '@/redux/features/trackingPage.slice';
import { Card, InlineGrid, RangeSlider, Text, hsbToHex } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { CustomBtnStyled, LookAndFeelStyleStyled } from './styled';
import { ColorPicker } from '@/components';

const colorPickerConfig = [
  {
    label: 'Main color',
    key: 'mainColor',
    apiKey: 'mainColor',
  },
  {
    label: 'Text color',
    key: 'textColor',
    apiKey: 'textColor',
  },
  {
    label: 'Button color',
    key: 'btnColor',
    apiKey: 'buttonColor',
  },
  {
    label: 'Button text color',
    key: 'btnNameColor',
    apiKey: 'buttonTextColor',
  },
];

const Style = () => {
  const dispatch = useDispatch();
  const data = useSelector(trackingPageSelector).value;
  const {
    fieldsChange,
    oldValue: {
      lookAndFeel: { style: oldStyle },
    },
  } = useSelector(trackingPageSelector);
  const { style } = data.lookAndFeel;
  const { colorPicker } = data.lookAndFeel.style;
  const { btnRadius } = data.lookAndFeel.style;
  const handleBtnRadius = (btnRadius: number) => {
    dispatch(
      trackingPageSlice.actions.handleTrackingPageValue({
        ...data,
        lookAndFeel: {
          ...data.lookAndFeel,
          style: {
            ...style,
            btnRadius,
          },
        },
      }),
    );
    const { dirtyField: btnRadiusField } = checkDirtyField(splitObjects(oldStyle, 0, 1), {
      ...splitObjects(style, 0, 1),
      btnRadius,
    });
    dispatch(
      trackingPageSlice.actions.handleTrackingPageFieldsChange({
        ...fieldsChange,
        general:
          !btnRadiusField && Object.entries(fieldsChange.general).length < 2
            ? undefined
            : {
                ...fieldsChange.general,
                buttonBorder: btnRadius,
              },
      }),
    );
  };

  const handleChangeColorHex = (key: string, apiKey: string) => (e: any) => {
    const { value } = e.target;
    if (isHexColor(value)) {
      const hsbColor = hexToHSB(value);
      const newColorPicker = { ...colorPicker };
      newColorPicker[key as keyof typeof colorPicker] = hsbColor;
      dispatch(
        trackingPageSlice.actions.handleTrackingPageValue({
          ...data,
          lookAndFeel: {
            ...data.lookAndFeel,
            style: {
              ...style,
              colorPicker: newColorPicker,
            },
          },
        }),
      );

      dispatch(
        trackingPageSlice.actions.handleTrackingPageFieldsChange({
          ...fieldsChange,
          general: {
            ...fieldsChange.general,
            [apiKey]: value,
          },
        }),
      );
    }
  };

  return (
    <LookAndFeelStyleStyled>
      <Card>
        <InlineGrid columns={{ xl: 4, lg: 2, md: 2, sm: 2, xs: 1 }} gap="200">
          {colorPickerConfig.map((item) => {
            return (
              <div key={item.key}>
                <Text as="h6" variant="headingSm">
                  {item.label}
                </Text>
                <div className="mt-8">
                  <ColorPicker
                    bgColor={hsbToHex(colorPicker[item.key as keyof typeof colorPicker])}
                    value={hsbToHex(colorPicker[item.key as keyof typeof colorPicker])}
                    onChange={handleChangeColorHex(item.key, item.apiKey)}
                  />
                </div>
              </div>
            );
          })}
        </InlineGrid>
        <div className="mt-16">
          <InlineGrid columns={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} gap="400" alignItems="end">
            <div>
              <RangeSlider min={0} max={25} label="Button Shape" value={btnRadius} onChange={handleBtnRadius} output />
            </div>
            <CustomBtnStyled
              background={hsbToHex(colorPicker.btnColor)}
              textColor={hsbToHex(colorPicker.btnNameColor)}
              borderRadius={btnRadius}
            >
              Track
            </CustomBtnStyled>
          </InlineGrid>
        </div>
      </Card>
    </LookAndFeelStyleStyled>
  );
};

export default Style;
