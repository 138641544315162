import { BgColorPalette } from '@/constants';
import styled from 'styled-components';

export const UploadFileStyled = styled.div<{ height: string }>`
  .Polaris-DropZone {
    &::after {
      display: none;
    }
  }

  .preview-text {
    display: none;
  }

  .border-frame:hover {
    cursor: pointer;

    .preview-text {
      color: white;
      background-color: ${BgColorPalette.OVERLAY};
      border-radius: 0.5rem;
      position: absolute;
      border-radius: 0.5rem;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.25rem;

      .Polaris-Icon {
        svg {
          fill: white;
        }
      }
    }
  }

  .Polaris-DropZone__Container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    .border-frame {
      border-radius: 0.5rem;
      width: 100%;
      height: ${({ height }) => height};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

 .preview-image {
    border-radius: 0.5rem;
    object-fit: cover;
  }
`;
