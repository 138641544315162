import { CustomCard } from '@/components';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { useGetTrackingLinkQuery, useUpdateTrackingLinkMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import settingsSlice, { trackingLinkSelector } from '@/redux/features/settings.slice';
import { ISettingParams } from '@/types/settings';
import { Checkbox, Divider, SkeletonBodyText, Text, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const TrackingLink = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const [state, setState] = useState<any>({});
  const { data, isLoading } = useGetTrackingLinkQuery();
  const [updateTrackingLink, updateTrackingLinkStatus] = useUpdateTrackingLinkMutation();
  const trackingLink = useSelector(trackingLinkSelector);

  useEffect(() => {
    if (data) {
      const convertData = {
        replaceCourierLink: Boolean(data.data?.replaceCourierLink),
        linkDescription: data.data?.linkDescription,
        addLinkToOrder: Boolean(data.data?.addLinkToOrder),
      };
      dispatch(settingsSlice.actions.handleTrackingLink(convertData));
      setState(convertData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangeTrackingLinkValue = (value: string | boolean, key: string) => {
    setApiError('');
    dispatch(
      settingsSlice.actions.handleTrackingLink({
        ...trackingLink,
        [key]: value,
      }),
    );
  };

  const handleUpdateTrackingLink = () => {
    const payload: ISettingParams.IUpdateTrackingLinkParams = {
      addLinkToOrder: Number(trackingLink.addLinkToOrder),
      linkDescription: trackingLink.linkDescription,
      replaceCourierLink: Number(trackingLink.replaceCourierLink),
    };
    updateTrackingLink(payload).then((res: any) => {
      if (res.data) {
        dispatch(commonSlice.actions.handleToast(handleToastMutation(res)));
        setState(trackingLink);
        return;
      }
      setApiError(getErrorFromAPI(res));
    });
  };

  return (
    <CustomCard
      title="Tracking Link Set-up"
      actionGroup={[
        {
          content: 'Save',
          onAction: handleUpdateTrackingLink,
          variant: 'primary',
          disabled: JSON.stringify(state) === JSON.stringify(trackingLink) || isLoading,
          loading: updateTrackingLinkStatus.isLoading,
        },
      ]}
    >
      {isLoading ? (
        <SkeletonBodyText lines={10} />
      ) : (
        <div>
          <Text as="h6" variant="headingSm">
            Replace Shopify’s native tracking link with your tracking page link
          </Text>
          <div className="mt-8">
            <Checkbox
              id="replaceCourierLink"
              label="Replace courier link"
              checked={trackingLink.replaceCourierLink}
              onChange={handleChangeTrackingLinkValue}
            />
          </div>

          <div style={{ marginBlock: 8 }}>
            <Divider />
          </div>

          <Text as="h6" variant="headingSm">
            Add a tracking link to Order Status page
          </Text>
          <div className="mt-8">
            <TextField
              id="linkDescription"
              label="Link description"
              value={trackingLink.linkDescription}
              onChange={handleChangeTrackingLinkValue}
              autoComplete="off"
              placeholder="Click here to get shipping updates"
              maxLength={255}
              showCharacterCount
              error={apiError}
            />
          </div>
          <div className="mt-8">
            <Checkbox
              id="addLinkToOrder"
              label="Add link to order"
              checked={trackingLink.addLinkToOrder}
              onChange={handleChangeTrackingLinkValue}
            />
          </div>
        </div>
      )}
    </CustomCard>
  );
};

export default TrackingLink;
