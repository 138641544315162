import { NavigationMenu, Provider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import { NavigationLink } from './components';
import { Environment, LocalStorageKey, embeddedNavLinksWithoutReview } from './constants';
import { CONSTANT, convertQueryStringToObject, setLocalStorageItem } from './helpers';
import authSlice, { tokenSelector } from './redux/features/auth.slice';
import translations from '@shopify/polaris/locales/en.json';
import AppRoutes from './routes';
import useScript from './hooks/useScript';

function App() {
  useScript();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const { token } = useSelector(tokenSelector);
  const searchToObj = convertQueryStringToObject(search);
  const configApp = {
    apiKey: process.env.REACT_APP_API_KEY || '',
    host:
      new URLSearchParams(search).get('host') ||
      window.btoa('admin.shopify.com/store/' + token?.shop.split('.')[0]).replaceAll('=', ''),
    forceRedirect: process.env.REACT_APP_ENV === Environment.Production,
  };

  const router = useMemo(
    () => ({
      location,
      history: { replace: (path: string) => navigate(path, { replace: true }) },
    }),
    [location, navigate],
  );

  // AUTHENTICATION
  useEffect(() => {
    if (searchToObj?.shop && searchToObj.shop !== token?.shop) {
      setLocalStorageItem(LocalStorageKey.OMEGA_SHOP, searchToObj.shop);
      setLocalStorageItem(LocalStorageKey.LOGIN_QUERY_STRING, search);
      dispatch(
        authSlice.actions.handleAuth({
          shop: searchToObj.shop,
          token: { ...searchToObj, urlParams: JSON.stringify(searchToObj) },
          isAuth: true,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppProvider i18n={translations} linkComponent={NavigationLink}>
      {process.env.REACT_APP_ENV !== Environment.Test && CONSTANT.isEmbedded ? (
        <Provider config={configApp} router={router}>
          <NavigationMenu
            navigationLinks={embeddedNavLinksWithoutReview}
            matcher={(link) => link.destination === location.pathname}
          />
          <AppRoutes />
        </Provider>
      ) : (
        <AppRoutes />
      )}
    </AppProvider>
  );
}

export default App;
