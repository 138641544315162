import { CustomCard, ErrorBanner, UpgradeBanner } from '@/components';
import trackingPageSlice, { eddSelector } from '@/redux/features/trackingPage.slice';
import { Button } from '@shopify/polaris';
import { NoteMajor, PlayCircleMajor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { StyledEstimatedDeliveryDate } from './styled';
import { LINK } from '@/constants';
import OrderDescription from './components/OrderDescription';
import TransitTimes from './components/TransitTimes';
import { AccountPlan } from '@/constants/enum';
import { accountSelector, christmasBannerSelector } from '@/redux/features/plan.slice';
import { useGetEDDSettingsQuery, useUpdateEddSettingsMutation } from '@/redux/api/api.caller';
import commonSlice from '@/redux/features/common.slice';
import { getErrorFromAPI, handleToastMutation } from '@/helpers/toast';
import { useEffect, useMemo, useState } from 'react';
import { defaultTransitTimes } from './config';
import { isLockFeature } from '@/helpers/feature';

const EstimatedDeliveryDate = () => {
  const dispatch = useDispatch();
  const [apiError, setApiError] = useState<string>('');
  const edd = useSelector(eddSelector);
  const plan = useSelector(accountSelector);
  const { formattedEndDate } = useSelector(christmasBannerSelector);
  const isActiveChristmasFeature = new Date() < new Date(formattedEndDate);
  const isLockedFeature =
    isLockFeature([AccountPlan.Starter, AccountPlan.Basic]) && !plan.isOldPaidUser && !isActiveChristmasFeature;

  const { data, isLoading } = useGetEDDSettingsQuery(undefined, { skip: isLockedFeature });
  const [updateEddSetting, updatedEddData] = useUpdateEddSettingsMutation();

  const eddBtnText = useMemo(() => {
    if (isLoading) {
      return 'Loading...';
    }
    if (edd.isEnableBtn) {
      return 'Disable';
    }
    return 'Enable';
  }, [edd.isEnableBtn, isLoading]);

  const handleClickEnableBtn = () => {
    updateEddSetting({ enabled: Number(!edd.isEnableBtn) }).then((data: any) => {
      if (data.data) {
        setApiError('');
        dispatch(commonSlice.actions.handleToast(handleToastMutation(data)));
        return;
      }
      setApiError(getErrorFromAPI(data));
    });
  };

  useEffect(() => {
    if (data && data.data) {
      dispatch(
        trackingPageSlice.actions.handleChangeEDD({
          ...edd,
          isEnableBtn: data.data.enabled,
          orderDescription: {
            ...edd.orderDescription,
            cutoffTime: data.data.cutoffTime,
            timezone: data.data.timezone,
            processingTime: { ...edd.orderDescription.processingTime, value: data.data.processingTime, error: false },
            businessDays: { ...edd.orderDescription.businessDays, value: data.data.businessDays, error: false },
          },
          transitTimes: data.data.transitTime,
        }),
      );
      return;
    }
    dispatch(
      trackingPageSlice.actions.handleChangeEDD({
        ...edd,
        isEnableBtn: 0,
        transitTimes: defaultTransitTimes,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <StyledEstimatedDeliveryDate>
        <UpgradeBanner isOpen={!!isLockedFeature} usedFeature={AccountPlan.Professional} />
        <CustomCard
          title="Estimated Delivery Date"
          actionGroup={[
            {
              content: eddBtnText,
              variant: !edd.isEnableBtn ? 'primary' : undefined,
              loading: updatedEddData.isLoading,
              disabled: isLoading || isLockedFeature,
              onAction: handleClickEnableBtn,
            },
          ]}
        >
          {apiError && (
            <div className="mb-8">
              <ErrorBanner isVisible>{apiError}</ErrorBanner>
            </div>
          )}
          <div className="group-btn">
            <Button variant="plain" icon={PlayCircleMajor} url={LINK.EDD_VIDEO}>
              Watch video
            </Button>
            <Button variant="plain" icon={NoteMajor} url={LINK.EDD_DOCS}>
              View document
            </Button>
          </div>
        </CustomCard>

        <OrderDescription />
        <TransitTimes />
      </StyledEstimatedDeliveryDate>
    </>
  );
};

export default EstimatedDeliveryDate;
