import { CustomCard } from '@/components';
import { Autocomplete, InlineGrid, Icon, TextField, Text, Button, Divider, SkeletonBodyText, EmptyState } from '@shopify/polaris';
import { useEffect, useMemo, useState } from 'react';
import { DropdownMinor, ExportMinor } from '@shopify/polaris-icons';
import { cardConfig, smsTextField } from './config';
import { SmsCreditStyled } from './styled';
import { useGetListPricingQuery, useGetSmsCreditQuery, useLazyGetSmsChargeLinkQuery } from '@/redux/api/api.caller';
import { calcPercent, exportExtensionFile, exportSMSPriceFile, isLockFeature } from '@/helpers';
import BalanceReportCard from './components/BalanceReportCard';
import { DataSeries, DonutChart } from '@shopify/polaris-viz';
import { AccountPlan } from '@/constants/enum';

interface State {
  amount: { value: string; error: string };
  amountSms: { value: string; error: string };
  country: {
    value: string;
    optionSelected: string;
    optionsFilter: Array<{ label: string; value: string; price: string }>;
  };
}

const pricePerMsgUs = 0.01185;

const SmsCredit = () => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const isLock = isLockFeature([AccountPlan.Starter]);
  const { data } = useGetSmsCreditQuery(undefined, { skip: isLock });
  const getlistPrice = useGetListPricingQuery(undefined, { skip: isLock });
  const [getLinkCharge, getLinkChargeStatus] = useLazyGetSmsChargeLinkQuery();
  const handleDataCredit = useMemo(() => {
    if (data) {
      const isOverQuota = data?.data.smsCreditBalance - data.data.totalExpenses < 0.02;
      const quotaConfig = isOverQuota ? cardConfig.overQuota : cardConfig.remainingQuota;
      const quota = {
        ...quotaConfig,
        isOverQuota,
        balance: (data.data.smsCreditBalance - data.data.totalExpenses).toFixed(2),
        smsValidAmount: Math.ceil((data.data.smsCreditBalance - data.data.totalExpenses) / pricePerMsgUs),
      };
      return quota;
    }
    return {
      isOverQuota: false,
      title: '',
      backgroundColor: '',
      label: '',
      balance: '',
      smsValidAmount: '',
    };
  }, [data]);

  const [baseListPrice, setBaseListPrice] = useState<Array<{ label: string; value: string; price: string }>>([]);
  const [state, setState] = useState<State>({
    amount: { value: '5', error: '' },
    amountSms: { value: '1', error: '' },
    country: {
      value: '',
      optionSelected: '',
      optionsFilter: [],
    },
  });

  useEffect(() => {
    if (getlistPrice.data) {
      const list = getlistPrice.data.data.map((item) => {
        return {
          label: item.country,
          value: item.countryCode,
          price: item.price,
        };
      });
      setBaseListPrice(list);
      setState({ ...state, country: { ...state.country, optionsFilter: list } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getlistPrice.data]);

  const handleChange = (value: string, key: keyof typeof state) => {
    const getError = () => {
      const { min, max, greater, less } = (smsTextField as any)[key];
      if (Number(value) < min) {
        return greater;
      }
      if (Number(value) > max) {
        return less;
      }
      return '';
    };
    setState({
      ...state,
      [key]: { value, error: getError() },
    });
  };
  const handleChangeInputCountry = (value: string) => {
    let newListprice = [...baseListPrice].filter((item) => item.label.toLocaleUpperCase().includes(value.toLocaleUpperCase()));
    setState({
      ...state,
      country: {
        ...state.country,
        optionsFilter: newListprice,
        value,
      },
    });
  };
  const updateSelection = (value: Array<string>) => {
    const selection = getlistPrice.data?.data.find((item) => item.countryCode === value[0]);
    if (selection) {
      setState({
        ...state,
        country: {
          ...state.country,
          optionSelected: value[0],
          value: selection.country,
        },
      });
    }
  };

  const textField = (
    <Autocomplete.TextField
      onChange={handleChangeInputCountry}
      label="To Country"
      labelHidden
      value={state.country.value}
      suffix={<Icon source={DropdownMinor} tone="base" />}
      placeholder="Search by country..."
      autoComplete="off"
    />
  );

  const calcCost = useMemo(() => {
    if (!state.country.optionSelected) return 0;
    const countryPrice = getlistPrice.data?.data.find((item) => item.countryCode === state.country.optionSelected)?.price;
    if (countryPrice) return Number((+state.amountSms.value * +countryPrice).toFixed(2));

    return 0;
  }, [getlistPrice.data?.data, state.amountSms.value, state.country.optionSelected]);

  const handleChargLink = () => {
    getLinkCharge(Number(state.amount.value)).then((res) => {
      if (res.data?.state === 1) {
        window.location.href = res.data.data.url;
      }
    });
  };

  const handleViewSMSPrice = async () => {
    try {
      setExportLoading(true);
      const { data, status } = await exportSMSPriceFile();
      if (status === 200 && data) {
        exportExtensionFile(data, 'Order_tracking_SMS_price');
      }
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const dataChart: DataSeries[] = useMemo(() => {
    if (
      data &&
      (data.data.smsCreditBalance - data.data.totalExpenses !== 0 || data.data.totalExpenses) !== 0 &&
      handleDataCredit
    ) {
      const { smsCreditBalance, totalExpenses } = data.data;

      return [
        {
          name: `Remaining quota (${calcPercent(smsCreditBalance - totalExpenses, smsCreditBalance)}%)`,
          color: handleDataCredit.isOverQuota ? 'rgb(234, 67, 53)' : 'rgba(0, 128, 96, 1)',
          data: [
            {
              key: 'Remaining quota',
              value: smsCreditBalance - totalExpenses,
            },
          ],
        },
        {
          name: `Used SMS (${calcPercent(totalExpenses, smsCreditBalance)}%)`,
          color: 'rgb(254, 179, 48)',
          data: [
            {
              key: 'Used SMS',
              value: totalExpenses,
            },
          ],
        },
      ];
    }

    return [];
  }, [data, handleDataCredit]);

  return (
    <SmsCreditStyled>
      <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
        <CustomCard title="Instance charge" display="flex">
          {(data && handleDataCredit) || isLock ? (
            <TextField
              id="amount"
              type="number"
              autoComplete="off"
              label="Amount"
              value={(+state.amount.value).toString()}
              error={state.amount.error}
              onChange={handleChange}
              min={smsTextField.amount.min}
              max={smsTextField.amount.max}
              connectedRight={
                <Button
                  variant="primary"
                  onClick={handleChargLink}
                  loading={getLinkChargeStatus.isLoading}
                  disabled={!!state.amount.error || isLock}
                >
                  Add fund
                </Button>
              }
            />
          ) : (
            <div style={{ height: 60 }}>
              <SkeletonBodyText lines={4} />
            </div>
          )}
        </CustomCard>
        <CustomCard
          title="SMS calculator"
          actionGroup={[
            {
              content: 'View SMS price',
              disabled: exportLoading,
              onAction: handleViewSMSPrice,
              variant: 'plain',
              icon: ExportMinor,
            },
          ]}
        >
          {(data && handleDataCredit) || isLock ? (
            <>
              <div className="calc-input-container">
                <div className="calc-title">
                  <Text as="h6" variant="bodySm">
                    Amount of SMS:
                  </Text>
                </div>

                <div className="calc-input">
                  <TextField
                    id="amountSms"
                    autoComplete="off"
                    label="Amount of SMS"
                    value={(+state.amountSms.value).toString()}
                    error={state.amountSms.error}
                    onChange={handleChange}
                    min={smsTextField.amountSms.min}
                    max={smsTextField.amountSms.max}
                    type="number"
                    labelHidden
                  />
                </div>
              </div>
              <div className="calc-input-container mt-16">
                <div className="calc-title">
                  <Text as="h6" variant="bodySm">
                    To Country :
                  </Text>
                </div>
                <div className="calc-input">
                  <Autocomplete
                    options={state.country.optionsFilter}
                    selected={[state.country.optionSelected]}
                    onSelect={updateSelection}
                    textField={textField}
                  />
                </div>
              </div>
              <div className="mt-16">
                <Divider />
              </div>
              <div className="mt-16 center">
                <Text as="h6" variant="headingMd">
                  Cost : ${calcCost}
                </Text>
              </div>
            </>
          ) : (
            <div style={{ height: 149 }}>
              <SkeletonBodyText lines={8} />
            </div>
          )}
        </CustomCard>
      </InlineGrid>
      <div className="mt-16 fz-16">
        <CustomCard title="Balance report (last 30 days)">
          {(data && handleDataCredit) || isLock ? (
            <>
              <div className="balance-report-container">
                <InlineGrid columns={{ xl: 2, lg: 2, md: 1, xs: 1, sm: 1 }} gap="400">
                  <CustomCard title="Total Balance" display="flex">
                    <div className="credit-balance">
                      <Text as="h6" variant="heading3xl" tone="success">
                        ${data?.data.smsCreditBalance.toFixed(2) || '0.00'}
                      </Text>
                    </div>
                  </CustomCard>
                  <CustomCard title="Expenses">
                    <div className="chart-container">
                      {dataChart.length === 0 || isLock ? (
                        <EmptyState image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
                          <span style={{ fontSize: 18 }}>No data</span>
                        </EmptyState>
                      ) : (
                        <div className="donut-chart">
                          <DonutChart data={dataChart} theme="Light" isAnimated legendPosition="bottom" />
                        </div>
                      )}
                    </div>
                  </CustomCard>
                  <BalanceReportCard
                    bgImage={handleDataCredit.backgroundColor || cardConfig.overQuota.backgroundColor}
                    title={handleDataCredit.title || 'Over Quota'}
                    topLeftText={handleDataCredit.balance}
                    topRightText="Balance"
                    bottomLeftText={handleDataCredit.smsValidAmount}
                    bottomRightText={`SMS ${handleDataCredit.label}`}
                  />
                  <BalanceReportCard
                    bgImage={cardConfig.usedSms.backgroundColor}
                    title="Used SMS"
                    topLeftText={data?.data.totalExpenses.toFixed(2) || ''}
                    topRightText="Balance"
                    bottomLeftText={data?.data.totalSmsSent || ''}
                    bottomRightText="SMS"
                  />
                </InlineGrid>
              </div>
            </>
          ) : (
            <div style={{ height: 600, marginTop: 16 }}>
              <SkeletonBodyText lines={30} />
            </div>
          )}
        </CustomCard>
      </div>
    </SmsCreditStyled>
  );
};
export default SmsCredit;
